import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Color, Material, Mesh, MeshStandardMaterial, Object3D, ObjectLoader, PCFSoftShadowMap, PerspectiveCamera, WebGLRenderer } from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import './App.css';
import Ring from "./ring/ring_0.json";

type HexNumber = number;

function useRing(container: RefObject<HTMLDivElement>) {
  const [scene, setScene] = useState<Object3D | null>(null);
  const [diamondMat, setDiamondMat] = useState<MeshStandardMaterial | null>();
  const [headMat, setHeadMat] = useState<MeshStandardMaterial | null>();
  const [ringMat, setRingMat] = useState<MeshStandardMaterial | null>();
  const [loading, setLoading] = useState(false);
  const [camera, setCamera] = useState<PerspectiveCamera | null>(null);
  const [renderer, setRenderer] = useState<WebGLRenderer | null>(null);
  const [controls, setControls] = useState<OrbitControls | null>(null);
  const [animateFlag, setAnimateFlag] = useState<number | null>(null);

  useEffect(() => {
    console.debug("Wrapper");
    if (!container.current) return;
    const wrapper = container.current;
    console.debug("Wrapper Valid");
    const loader = new ObjectLoader();
    setLoading(true);
    loader.parse(Ring, (scene) => {
      setLoading(false);
      setScene(scene);
      setHeadMat((scene.getObjectByName("Head") as unknown as Mesh).material as MeshStandardMaterial);
      setRingMat((scene.getObjectByName("Setting") as unknown as Mesh).material as MeshStandardMaterial);
      setDiamondMat((scene.getObjectByName("HeadDiamond") as unknown as Mesh).material as MeshStandardMaterial);
      const camera = new PerspectiveCamera(50, wrapper.clientWidth / wrapper.clientHeight, 0.1, 1000);
      camera.position.set(0, 0, 53.72);
      setCamera(camera);

      const renderer = new WebGLRenderer({ antialias: true });
      setRenderer(renderer);
      renderer.setSize(wrapper.clientWidth, wrapper.clientHeight);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = PCFSoftShadowMap;

      wrapper.appendChild(renderer.domElement);
      renderer.render(scene, camera);

      const controls = new OrbitControls(camera, renderer.domElement);
      setControls(controls);
      controls.enableDamping = true;
      controls.enableZoom = true;
      controls.autoRotate = true;
      console.debug("Render Inited");

      (scene.getObjectByName("HMaterial") as unknown as Material);
      (function animate() {
        controls.update();
        requestAnimationFrame(animate);
        renderer.render(scene, camera);
      }());
      console.log({ scene, color: new Color(0, 0, 0) });
    });

  }, [container])

  const setHeadColor = useCallback((color: HexNumber) => {
    console.debug("setHeadColor");
    if (!scene || !headMat) return;
    headMat.color.setHex(color)
  }, [scene, headMat]);

  const setSettingColor = useCallback((color: HexNumber) => {
    if (!scene || !ringMat) return;
    ringMat.color.setHex(color)
  }, [scene, ringMat]);

  // const play = useCallback(() => {
  //   if (!scene || !camera || !renderer || !controls) return;
  //   (function animate() {
  //     controls.update();
  //     setAnimateFlag(requestAnimationFrame(animate));
  //     renderer.render(scene, camera);
  //   }());
  // }, [scene, camera, renderer, controls]);

  // const stop = useCallback(() => {
  //   if (animateFlag) {
  //     cancelAnimationFrame(animateFlag);
  //   }
  // }, [animateFlag]);

  const loadAlterHead = useCallback(() => {
    const loader = new GLTFLoader();
    setLoading(true);
    loader.load("/head_six.glb", gltf => {
      setLoading(false);
      let originHead = scene?.getObjectByName("Head") as unknown as Mesh;
      originHead.removeFromParent();
      let newHead = (gltf.scene.getObjectByName("Head") as unknown as Object3D);
      ((newHead.children[1] as unknown as Mesh).material as MeshStandardMaterial) = headMat!;
      ((newHead.children[0] as unknown as Mesh).material as MeshStandardMaterial) = diamondMat!;
      scene?.getObjectByName("HeadDiamond")?.removeFromParent();
      scene!.add(newHead);
      // scene!.add(gltf.scene);
      console.info("GLTF loaded: ", { gltf });
    });

  }, [scene, diamondMat, headMat]);

  return {
    setHeadColor,
    setSettingColor,
    loadAlterHead,
    loading,
    // play,
    // stop,
  }
}
type ColorParams = {
  color: HexNumber,
  title: string,
  id: number
};
type ColorSelectionProps = { colors: ColorParams[], onChange: (arg0: ColorParams) => void };
function ColorSelection({ colors, onChange }: ColorSelectionProps) {
  return <ul className='color-list'>{colors.map(color => <li key={color.id}><button onClick={() => onChange(color)}>{color.title}</button></li>)}</ul>;
}

const colors: ColorParams[] = [
  {
    title: "14K White Gold",
    color: 0xffffff,
    id: 0,
  },
  {
    title: "14K Yellow Gold",
    color: 0xf0b856,
    id: 1,
  },
  {
    title: "14K Rose Gold",
    color: 0xffca9e,
    id: 2,
  }
];

function RingScene() {
  const container = useRef<HTMLDivElement>(null);
  console.log("UseRing");
  const { setHeadColor, setSettingColor, loadAlterHead, loading } = useRing(container);

  return <>
    {loading && <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, zIndex: 99999, background: "rgba(15,15,15,0.3)", display: 'flex', alignItems: "center", justifyContent: "center" }}>
      <h3>Loading ...</h3>
    </div>}
    <div style={{ height: "100%", overflow: "hidden" }} ref={container}>
      <h3>
        Diamond
      </h3>
    </div>
    <button onClick={loadAlterHead}>Load Six Prong</button>
    {/* <button onClick={play} >Play</button>
    <button onClick={stop} >Stop</button> */}
    <fieldset>
      <legend>
        Head Metal
      </legend>
      <ColorSelection colors={colors} onChange={color => setHeadColor(color.color)} />
    </fieldset>
    <fieldset>
      <legend>
        Shank Metal
      </legend>
      <ColorSelection colors={colors} onChange={color => setSettingColor(color.color)} />
    </fieldset>
  </>
    ;
}

function App() {
  return (
    <div className="App">
      <div style={{ width: "100%", maxWidth: "600px", height: "500px", border: "1px inset", margin: "1em 0 auto 0" }}>
        <RingScene />
      </div>
    </div>
  );
}

export default App;
